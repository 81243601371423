<template>
  <Details
    ref="details"
    title="Community"
    resource="customerCommunities"
    :base-path="basePath"
    routeIdParam="communityId"
    :request-params="{customerId: customerId}"
    title-template="{name} community"
  >
    <template v-slot:default="detailsProps">
      <div class="flex justify-end">
        <button v-if="!detailsProps.record.deletedAt" @click="editCommunity" class="mb-2">
          <icon name="pencil" class="edit-icon"/>
        </button>
      </div>

      <DetailsBar class="relative mb-6 form-narrow">
        <div class="form-section-title" :style="{ paddingTop: 0 }">main information</div>
        <div class="form-row">
          <div class="form-col">
            <div class="form-row">
              <div class="form-col">
                <div class="form-label">community name</div>
                <div class="text-sm">{{ detailsProps.record.name }}</div>
              </div>
              <div class="form-col">
                <div class="form-label">community owner name</div>
                <div class="text-sm">{{ detailsProps.record.ownerName }}</div>
              </div>
            </div>
          </div>
          <div class="form-col">
            <div class="form-label">community id</div>
            <div class="text-sm flex items-end">
              <span>{{ detailsProps.record.id }}</span>
              <clipboard-button
                class="focus:outline-none px-2"
                :style="{marginBottom: '0.125rem'}"
                :value="detailsProps.record.id"
              ></clipboard-button>
            </div>
          </div>
        </div>
        <div class="form-row mt-6">
          <div class="form-col">
            <div class="form-label">description</div>
            <div class="text-sm">{{ detailsProps.record.description }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">community email</div>
            <div class="text-sm">{{ detailsProps.record.email }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">community leasing email</div>
            <div class="text-sm">{{ detailsProps.record.leasingEmail || '-' }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">online payment email</div>
            <div class="text-sm">{{ detailsProps.record.onlinePaymentEmail || '-' }}</div>
          </div>
        </div>
        <div class="form-row mt-6">
          <div class="form-col">
            <div class="form-label">phone</div>
            <div class="text-sm">{{ detailsProps.record.phone || '-' }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">sms number</div>
            <div class="text-sm">{{ detailsProps.record.smsPhone || '-' }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">community url</div>
            <div class="text-sm">{{ detailsProps.record.websiteUrl || '-' }}</div>
          </div>
          <div class="form-col">
            <div class="form-label">corporate url</div>
            <div class="text-sm">{{ detailsProps.record.corporateUrl || '-' }}</div>
          </div>
        </div>
      </DetailsBar>

      <div class="form-row">
        <div class="form-col">
          <div class="form-label">facebook url</div>
          <div class="text-sm">{{ detailsProps.record.facebookUrl || '-' }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">twitter url</div>
          <div class="text-sm">{{ detailsProps.record.twitterUrl || '-' }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">instagram url</div>
          <div class="text-sm">{{ detailsProps.record.instagramUrl || '-' }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">pinterest url</div>
          <div class="text-sm">{{ detailsProps.record.pinterestUrl || '-' }}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <div class="form-label">floorplans url</div>
          <div class="text-sm">{{ detailsProps.record.floorplansUrl || '-' }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">virtual tour url</div>
          <div class="text-sm">{{ detailsProps.record.virtualTourUrl || '-' }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">tour scheduling model</div>
          <div class="text-sm">{{ detailsProps.record.tourSchedulingModel }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">tour duration</div>
          <div class="text-sm">{{ detailsProps.record.tourDuration }}</div>
        </div>
      </div>

      <div class="form-section-title flex-col">
        <span>licensing options</span>
        <span class="text-xs">applications your community has access to</span>
      </div>
      <div class="mb-8">
        <InfoText v-if="noLicensing">no licensing for this community</InfoText>
        <List
          v-else
          title="licensing options"
          resource=""
          :data="detailsProps.record.licenseBindings || []"
          :fields="licensingFields"
          track-by="bundleId"
        />
      </div>

      <div class="form-section-title">mailing address</div>
      <div class="form-row">
        <div class="form-col">
          <div class="form-label">city</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'mailingAddress.city') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">state</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'mailingAddress.state') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">zip</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'mailingAddress.zip') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">street line 1</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'mailingAddress.addressLine1') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">street line 2</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'mailingAddress.addressLine2') }}</div>
        </div>
      </div>
      <div class="form-section-title">physical address</div>
      <div v-if="sameAsMailingAddress" class="text-sm">same as mailing address</div>
      <div v-else class="form-row">
        <div class="form-col">
          <div class="form-label">city</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.city') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">state</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.state') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">zip</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.zip') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">street line 1</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.addressLine1') }}</div>
        </div>
        <div class="form-col">
          <div class="form-label">street line 2</div>
          <div class="text-sm">{{ getNestedInfo(detailsProps.record, 'address.addressLine2') }}</div>
        </div>
      </div>
      <div class="form-section-title">community management</div>
      <div class="relative list-container mb-4">
        <vuetable
          rowClass="table-row"
          :api-mode="false"
          :css="css.table"
          :fields="communityManagementFields"
          :data="detailsProps.record.communityManagement"
          :no-data-template="'no data'"
          track-by="id"
        />
      </div>
      <div class="form-section-title">leasing agent</div>
      <div class="relative list-container mb-4">
        <vuetable
          rowClass="table-row"
          :api-mode="false"
          :css="css.table"
          :fields="leasingAgentFields"
          :data="detailsProps.record.leasingAgent"
          :no-data-template="'no data'"
          track-by="id"
        >
        </vuetable>
      </div>
      <div class="form-section-title">hours of operation</div>
      <div class="relative list-container mb-4">
        <vuetable
          rowClass="table-row"
          :api-mode="false"
          :css="css.table"
          :fields="hoursFields"
          :data="detailsProps.record.workingHours"
          :no-data-template="'no data'"
          track-by="description"
        >
        </vuetable>
      </div>
      <div class="form-section-title">community logo</div>
      <div class="mb-4">
        <img
          v-if="detailsProps.record.communityLogoUrl"
          :src="detailsProps.record.communityLogoUrl"
          alt="community logo"
          class="h-32 bg-contain"
        />
        <InfoText v-else>community logo is not uploaded</InfoText>
      </div>
      <div class="form-section-title">community image</div>
      <div>
        <img
          v-if="detailsProps.record.communityImageUrl"
          :src="detailsProps.record.communityImageUrl"
          alt="community image"
          class="h-32 bg-contain"
        />
        <InfoText v-else>community image is not uploaded</InfoText>
      </div>
    </template>
  </Details>
</template>

<script>
  import {get, isEqual} from "lodash-es";
  import Vuetable from 'vue3-vuetable';
  import ActionsMixin from "@/mixins/ActionsMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Icon from "@/components/ui/Icon";
  import AuthMixin from "@/components/auth/AuthMixin";
  import List from '@/components/auth/list/List';
  import Details from '@/components/auth/details/Details';
  import DetailsBar from "@/components/auth/details/DetailsBar";
  import UserField from "@/components/auth/list/fields/UserField";
  import InfoText from "@/components/ui/InfoText";
  import AdvancedOptionsField from '@/components/auth/list/fields/AdvancedOptionsField';
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

  export default {
    name: 'CommunityDetails',
    components: {
      InfoText,
      Vuetable,
      Icon,
      Details,
      DetailsBar,
      List,
      ClipboardButton,
    },
    mixins: [ModalNavigation, ActionsMixin, AuthMixin, NotifyMixin],
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      basePath: function () {
        return this.$route.name.replace('details', 'index');
      },
      licensingFields() {
        return [
          {
            name: 'bundleName',
            title: 'applications list',
            titleClass: 'pr-2 py-2',
            dataClass: 'pr-2 text-sm',
          },
          {
            name: AdvancedOptionsField,
            title: 'advanced options',
            switch: {
              source: 'attributes',
              defaultOptions: ({bundleId}) => this.defaultOptions[bundleId] || {},
            },
          },
        ];
      },
    },
    data() {
      return {
        communityFilesLoading: false,
        sameAsMailingAddress: false,
        noLicensing: true,
        communityManagementFields: [
          {
            name: UserField,
            title: 'name and email',
            switch: {
              getName: rowData => rowData.name,
              getEmail: rowData => rowData.email,
            },
            titleClass: 'pr-2 py-2',
            dataClass: 'pr-2 text-sm',
            width: '32%'
          },
          {
            name: 'phone',
            title: 'phone',
            titleClass: 'p-2',
            dataClass: 'px-2 text-sm',
            width: '34%'
          },
          {
            name: 'role',
            title: 'role',
            titleClass: 'p-2',
            dataClass: 'px-2 text-sm',
            width: '34%',
            formatter: (value) => {
              return this.managementRoles[value] || value;
            }
          }
        ],
        leasingAgentFields: [
          {
            name: UserField,
            title: 'name and email',
            switch: {
              getName: rowData => rowData.name,
              getEmail: rowData => rowData.email,
            },
            titleClass: 'pr-2 py-2',
            dataClass: 'pr-2 text-sm',
            width: '32%',
          },
          {
            name: 'phone',
            title: 'phone',
            titleClass: 'p-2',
            dataClass: 'px-2 text-sm',
            width: '68%',
          },
        ],
        hoursFields: [
          {
            name: 'description',
            title: 'day(s)',
            titleClass: 'py-2',
            dataClass: 'text-sm',
          },
          {
            name: 'open',
            title: 'open',
            titleClass: 'p-2',
            dataClass: 'px-2 text-sm',
          },
          {
            name: 'close',
            title: 'close',
            titleClass: 'p-2',
            dataClass: 'px-2 text-sm',
          },
        ],
        css: {
          table: {
            tableClass: 'table-auto w-full',
            tableBodyClass: '',
            tableHeaderClass: 'px-4 py-2',
            tableWrapper: 'overflow-x-auto',
            loadingClass: 'loading',
            ascendingIcon: 'blue chevron up icon',
            descendingIcon: 'blue chevron down icon',
            ascendingClass: 'sorted-asc',
            descendingClass: 'sorted-desc',
            sortableIcon: 'grey sort icon',
            handleIcon: 'grey sidebar icon',
          },
        },
        managementRoles: {},
        defaultOptions: {},
      }
    },
    methods: {
      getInitialValues(initialValues) {
        return {
          ...initialValues,
          sameAsMailingAddress: isEqual(initialValues.mailingAddress, initialValues.address)
        }
      },
      getNestedInfo(data, field) {
        return get(data, field);
      },
      editCommunity() {
        this.$router.push({
          name: this.$route.name.replace('details', 'edit'),
          params: this.$route.params,
        });
      },
      deleteCommunity() {
        this.$authDataProvider.delete('customerCommunities', {id: this.$route.params.communityId, customerId: this.customerId})
            .then(() => {
              this.notifySuccess('community deleted');
              this.$refs.details?.redirectToList();
            })
            .catch(error => this.notifyError(error.message))
      },
      fetchManagementRoles() {
        this.$authDataProvider.getList('managementRoles')
            .then(content => {
              this.managementRoles = content.reduce((acc, { value, description }) => (
                  {...acc, [value]: description.toLowerCase() }
                  ), {});
            })
            .catch((err) => this.notifyError(err.message))
      },
      fetchBundles() {
        this.$authDataProvider.getList('bundles', {
          page: 0,
          size: 999,
          nonDeleted: true,
        }).then(({content}) => {
          this.defaultOptions = content.reduce((acc, {id, apps, type}) => ({
            ...acc,
            [id]: {
              apps: apps.map(({appId, appName, attributes}) => ({appId, appName, attributes})),
              type,
            },
          }), {});
        });
      },
    },
    mounted() {
      Promise.all([
        this.fetchManagementRoles(),
        this.fetchBundles(),
      ]);

      this.$watch('$refs.details.record', function (record) {
        const {deletedAt, mailingAddress, address, licenseBindings} = record;

        this.sameAsMailingAddress = isEqual(mailingAddress, address);
        this.noLicensing = licenseBindings?.length === 0;

        if (!deletedAt && this.isQuext) {
          this.addConfirmAction(
              {
                id: 'auth-delete-community',
                title: "delete community",
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this community?',
                confirmBtnText: 'yes',
                cancelBtnText: 'no',
                confirmationType: 'warning'
              },
              this.deleteCommunity
          )
        }
      });
    }
  };
</script>
<style scoped>
  .list-container :deep(th) {
    @apply border-b font-500 text-xs;
  }
  .list-container :deep(.vuetable-empty-result) {
    @apply text-sm text-center;
  }
</style>
